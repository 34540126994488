<template>
  <div>
    <v-container class="px-0 d-flex flex-row align-center">
      <v-row>
        <v-col cols="12" sm="2" class="my-auto">
          <v-text-field
            v-model="params.search"
            hide-details
            :placeholder="$t('helpers.filterName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2" class="my-auto">
          <v-select
            v-model="params.subCategory"
            :items="energyStorageSelectOptions"
            elevation="0"
            solo
            hide-details
            @change="onSubCategoryChange"
          ></v-select
        ></v-col>
        <v-col
          cols="12"
          :sm="noSubCategorySelected ? '2' : '3'"
          class="my-auto"
          v-if="showFilterAccordingToSubCategory(subCategoriesObj.BATTERY.value)"
        >
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div class="filterButton" v-bind="attrs" v-on="on">
                <span class="rangeNumber minRange" v-if="isMoreThanZero(powerFilterRange[0])">{{
                  powerFilterRange[0]
                }}</span>
                {{ $t('helpers.filterPower') }}
                <span class="rangeNumber maxRange" v-if="isMoreThanZero(powerFilterRange[1])">{{
                  powerFilterRange[1]
                }}</span>
              </div>
            </template>
            <v-list :width="$vuetify.breakpoint.sm ? '100%' : '300'" height="auto">
              <v-range-slider
                class="pt-6 pb-2 px-5"
                v-model="powerFilterRange"
                thumb-label="always"
                :thumb-size="24"
                hide-details="true"
              />
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          :sm="noSubCategorySelected ? '2' : '3'"
          class="my-auto"
          v-if="showFilterAccordingToSubCategory(subCategoriesObj.CONTROLE_MODULE.value)"
        >
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div class="filterButton" v-bind="attrs" v-on="on">
                <span class="rangeNumber minRange" v-if="isMoreThanZero(supportedFilterRange[0])">{{
                  supportedFilterRange[0]
                }}</span>
                {{ $t('helpers.filterSupportedAmount') }}
                <span class="rangeNumber maxRange" v-if="isMoreThanZero(supportedFilterRange[1])">{{
                  supportedFilterRange[1]
                }}</span>
              </div>
            </template>
            <v-list :width="$vuetify.breakpoint.sm ? '100%' : '300'" height="auto">
              <v-range-slider
                class="pt-6 pb-2 px-5"
                v-model="supportedFilterRange"
                thumb-label="always"
                :thumb-size="24"
                hide-details="true"
              />
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" :sm="noSubCategorySelected ? '2' : '3'" class="my-auto">
          <v-select
            v-model="sortPrice"
            :items="pricingSort"
            elevation="0"
            :label="$t('helpers.filterSortByPrice')"
            solo
            hide-details
          ></v-select
        ></v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        ></v-row
      >
    </v-container>
    <v-container class="white" :class="containerPadding ? 'pa-8' : 'pa-0'">
      <v-row>
        <v-col class="font-weight-bold" col="12" sm="2">{{ $t('helpers.product') }}</v-col>
        <v-col class="font-weight-bold" col="12" sm="10">
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="font-weight-bold" col="0" sm="6"> </v-col>
              <v-col class="font-weight-bold text-center" col="4" sm="2"
                >{{ $t('helpers.price') }} ({{ $t('helpers.net') }}) /
                {{ $t('helpers.piecesAmount') }}</v-col
              >
              <v-col class="font-weight-bold text-center" col="4" sm="2">{{
                $t('helpers.amount')
              }}</v-col>
              <v-col class="font-weight-bold text-right" col="4" sm="2"
                >{{ $t('helpers.sum') }} ({{ $t('helpers.net') }})</v-col
              >
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col class="my-0 py-0"><v-divider></v-divider> </v-col>
      </v-row>
      <div v-if="!count" class="text-center mt-3">{{ $t('helpers.productsNotFound') }}</div>
      <catalog-list-item
        v-for="product in products"
        :key="product._id"
        :addAction="addAction"
        :removeAction="removeAction"
        :isDisabledAction="isDisabledAction"
        :onWizard="onWizard"
        :product="product"
        :isCompatibleWithPower="isCompatibleWithPower"
      ></catalog-list-item>
    </v-container>
    <v-row class="mt-3" v-if="isPaginationVisible"
      ><v-col
        ><div class="text-center">
          <v-pagination
            v-model="params.page"
            :length="length"
            :total-visible="params.limit"
          ></v-pagination></div></v-col
    ></v-row>
  </div>
</template>
<script>
import ProductService from '../../services/ProductService';
import CatalogListItem from '../../components/CatalogListItem.vue';
import { pricingSort } from '../../static/pricingSort';
import { categoriesComputedMixin } from '../../mixins/categoriesComputedMixin';

export default {
  components: { CatalogListItem },
  mixins: [categoriesComputedMixin],
  name: 'EnergyStorageList',
  props: {
    onWizard: { type: Boolean, default: false },
    containerPadding: { type: Boolean, default: true },
    addAction: { type: String },
    removeAction: { type: String },
    isCompatibleWithPower: { type: Boolean, default: true },
    isDisabledAction: { type: Boolean, default: false },
  },
  data() {
    return {
      products: [],
      sortPrice: '',
      sortPhrase: '',
      length: 1,
      count: 0,
      powerFilterRange: [0, 0],
      supportedFilterRange: [0, 0],
      subCategoryView: '',
      params: {
        category: '',
        subCategory: '',
        page: 1,
        limit: 6,
        search: '',
        sort: '-stock',
        powerFrom: '',
        powerTo: '',
        supportedFrom: '',
        supportedTo: '',
      },
    };
  },
  watch: {
    'params.page': function () {
      this.fetchProducts();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    sortPrice: function () {
      this.sortPhrase = this.sortPrice;
    },
  },
  methods: {
    showFilterAccordingToSubCategory(filterName) {
      return this.noSubCategorySelected
        ? this.noSubCategorySelected
        : filterName === this.params.subCategory;
    },
    isMoreThanZero(value) {
      return parseInt(value) > 0;
    },
    onSubCategoryChange() {
      this.params.powerFrom = '';
      this.params.powerTo = '';
      this.params.supportedFrom = '';
      this.params.supportedTo = '';
      this.powerFilterRange = [0, 0];
      this.supportedFilterRange = [0, 0];
    },
    clearParams() {
      this.sortPhrase = '';
      this.sortPrice = '';
      this.powerFilterRange = [0, 0];
      this.supportedFilterRange = [0, 0];
      this.params = {
        category: this.categoriesObj.ENERGY_STORAGE.value,
        subCategory: this.subCategoryView,
        page: 1,
        limit: 6,
        search: '',
        sort: '-stock',
        powerFrom: '',
        powerTo: '',
        supportedFrom: '',
        supportedTo: '',
      };
      this.fetchProducts();
    },
    onSearch() {
      this.params.page = 1;
      if (this.sortPhrase) this.params.sort = this.sortPhrase;
      if (this.powerFilterRange.some((number) => number > 0)) {
        this.params.powerFrom = this.powerFilterRange[0];
        this.params.powerTo = this.powerFilterRange[1];
      }
      if (this.supportedFilterRange.some((number) => number > 0)) {
        this.params.supportedFrom = this.supportedFilterRange[0];
        this.params.supportedTo = this.supportedFilterRange[1];
      }
      this.fetchProducts();
    },
    async fetchProducts() {
      try {
        const response = await ProductService.getAll({
          ...this.params,
          visibility: true,
        });

        this.products = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    noSubCategorySelected() {
      return !this.params?.subCategory;
    },
    isPaginationVisible() {
      return this.length > 1;
    },
    pricingSort() {
      return pricingSort();
    },
  },
  created() {
    const { subCategory } = this.$route.query;

    this.params.category = this.categoriesObj.ENERGY_STORAGE.value;

    if (subCategory) {
      const showAllEnergyStorageProducts = subCategory === this.categoriesObj.ENERGY_STORAGE.value;

      this.params.subCategory = showAllEnergyStorageProducts ? '' : subCategory;
      this.subCategoryView = this.params.subCategory;
    }
    this.fetchProducts();
  },
};
</script>
<style lang="scss" scoped>
.filterButton {
  position: relative;
  width: 100%;
  min-height: 48px;
  display: grid;
  place-content: center;
  background: #ffffff;
  border-radius: 4px;
}
.sliderText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rangeNumber {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #616161;
  font-size: 12px;
}

.minRange {
  left: 8px;
}

.maxRange {
  right: 8px;
}

.btnMenu {
  color: #000000;
  font-weight: 400 !important;
}
</style>
