<template>
  <title-wrapper :title="$t('components.energyStorage.title')">
    <energy-storage-list></energy-storage-list>
    <v-row class="mt-3">
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <primary-button :onClick="goFoward">{{
          $t('components.energyStorage.summary')
        }}</primary-button>
      </v-col>
    </v-row>
  </title-wrapper>
</template>
<script>
import EnergyStorageList from './EnergyStorageList.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';

export default {
  components: { TitleWrapper, PrimaryButton, EnergyStorageList },
  name: 'EnergyStorage',
  methods: {
    goFoward() {
      this.$router.push({ name: 'TransactionSummary' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
