<template>
  <title-wrapper :title="$t('components.manageOrders.title')">
    <div>
      <v-progress-linear v-if="loading" indeterminate color="primary"> </v-progress-linear>
      <v-container v-else class="px-0">
        <v-row class="align-center">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="params.search"
              hide-details
              :placeholder="$t('components.manageOrders.searchId')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="params.status"
              :items="statuses"
              item-value="name"
              item-text="text"
              hide-details
              :placeholder="$t('helpers.status')"
              solo
            ></v-select
          ></v-col>
          <v-col cols="12" sm="3">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="slide-y-transition"
              offset-y
              :nudge-top="-10"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.dates"
                  :label="$t('helpers.date')"
                  readonly
                  hide-details
                  clearable
                  @click:clear="onClearDate"
                  :placeholder="$t('helpers.date')"
                  solo
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">
                    <v-icon small :class="{ active: dateMenu }">$arrowIcon</v-icon>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="params.dates"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '400'"
                no-title
                scrollable
                range
                :first-day-of-week="dayOfWeek"
                :locale="selectedLanguage"
                :min="params.dates ? params.dates[0] : ''"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
              <v-icon>mdi-magnify</v-icon>
            </v-btn></v-col
          >
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
              <v-icon>mdi-cancel</v-icon>
            </v-btn></v-col
          >
        </v-row>
        <v-container class="white my-4">
          <v-row
            ><v-col
              ><v-data-table
                :headers="headers"
                :items="orders"
                :items-per-page="params.limit"
                hide-default-footer
                class="elevation-0"
                :header-props="tableHeaderProps"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                @update:sort-by="sortByFunc"
                @update:sort-desc="sortDescFunc"
                :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
              >
                <template v-slot:[`item.convertedTotalPrice`]="{ item }">
                  <span>{{ formatCurrency(item.totalPrice, item.currency, false) }}</span>
                </template>
                <template v-slot:[`item.status.name_for_customer`]="{ item }">
                  <v-chip :color="item.status.color"
                    ><span class="white--text">{{
                      getTextForCustomer(item.status.id) || 'Błąd'
                    }}</span></v-chip
                  >
                  <v-tooltip top v-if="item.missingProducts.length">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" class="ml-1" v-bind="attrs" v-on="on">
                        mdi-alert-circle
                      </v-icon>
                    </template>

                    <p class="my-0">{{ $t('components.manageOrders.orderNotComplete') }}:</p>
                    <p
                      v-for="(missingProduct, index) in item.missingProducts"
                      :key="missingProduct.code"
                      class="my-0"
                    >
                      {{ index + 1 }}. {{ missingProduct.name }},
                      {{ $t('components.manageOrders.productCode') }}: {{ missingProduct.code }},
                      {{ $t('components.manageOrders.missingAmount') }}:
                      {{ missingProduct.missingQuantity }}
                    </p>
                    <p v-if="item.missingSkus.length">
                      {{ $t('components.manageOrders.noSKU') }}:
                      <span v-for="(sku, index) in item.missingSkus" :key="sku">
                        {{ sku }}<span>{{ index !== item.missingSkus.length - 1 ? ',' : '' }}</span>
                      </span>
                    </p>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.user?.fullName`]="{ item }">
                  <span>{{ item.user?.fullName }} </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                      <v-icon small color="black" class="mr-2" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-card
                      ><v-list-item
                        class="hover-item-popup"
                        @click="
                          () => {
                            showOrderDetails(item._id);
                          }
                        "
                      >
                        <v-list-item-content> {{ $t('helpers.showDetails') }} </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-menu>
                </template></v-data-table
              ></v-col
            ></v-row
          >
          <slot name="below"></slot>
        </v-container>
        <v-row v-if="isPaginationVisible"
          ><v-col
            ><div class="text-center">
              <v-pagination
                v-model="params.page"
                :length="length"
                total-visible="5"
              ></v-pagination></div></v-col
        ></v-row>
      </v-container>
    </div>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import OrderService from '../../../services/OrderService';
import { format, isBefore } from 'date-fns';
import { CLIENT_ORDER_STATUSES, STATUSES } from '../../../static/orderStatuses';
import { pl } from 'date-fns/locale';
import { userDataComputed, selectedLanguageComputed } from '../../../store/helper';
import { roles } from '../../../static/roles';
import { formatCurrency } from '../../../mixins/formatCurrency';
import { sortHandler } from '../../../mixins/sortHandler';
import { dateHandler } from '../../../mixins/dateHandler';
import VueI18n, { selectedLocale } from '../../../plugins/i18n';
import { omitBy, isNil } from 'lodash';
import { tableHeaderProps } from '../../../mixins/tableHeaderProps';

const startingParams = {
  search: '',
  visibility: '',
  status: '',
  sort: '',
  dates: [],
  page: 1,
  limit: 10,
};

export default {
  components: { TitleWrapper },
  mixins: [formatCurrency, sortHandler, dateHandler, tableHeaderProps],
  data() {
    return {
      count: 0,
      length: 0,
      dayOfWeek: 1,
      orders: [],
      loading: true,
      dateMenu: false,
      productsCount: 0,
      params: { ...startingParams },
    };
  },
  watch: {
    'params.page': function () {
      this.getFilteredByRole();
    },
    date: function (val) {
      if (val && val.length === 2) {
        if (isBefore(new Date(val[1]), new Date(val[0]))) {
          this.date = [val[1], val[0]];
        }
      }
      this.getFilteredByRole();
    },
  },
  computed: {
    ...selectedLanguageComputed,
    headers() {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'baselinkerId',
        },
        { text: VueI18n.t('helpers.date'), value: 'date' },
        { text: VueI18n.t('helpers.totalPrice'), value: 'convertedTotalPrice' },
        { text: 'Status', value: 'status.name_for_customer' },
        { text: VueI18n.t('helpers.purchaser'), value: 'user.fullName' },
        { text: '', value: 'actions', sortable: false },
      ];
    },

    statuses() {
      return CLIENT_ORDER_STATUSES();
    },
    orderStatuses() {
      return STATUSES();
    },
    ...userDataComputed,
    isPaginationVisible() {
      return this.length > 1;
    },
  },
  methods: {
    getTextForCustomer(id) {
      return this.orderStatuses.find((item) => item.id === id)?.name_for_customer;
    },

    showOrderDetails(orderId) {
      this.$router.push({
        name: 'UserSingleOrder',
        params: { orderId: orderId },
      });
    },
    mapParams() {
      this.sortDates();

      const params = {
        page: this.params.page,
        limit: this.params.limit,
        search: this.params.search,
        status: this.params.status,
        sort: this.params.sort,
        dateFrom: this.params.dateFrom,
        dateTo: this.params.dateTo,
      };

      return omitBy(params, isNil);
    },
    async getFilteredEmployeeData() {
      try {
        const response = await OrderService.getAllUserOrders(this.mapParams(this.params));
        this.orders = response.data.map((item) => {
          item.date = format(new Date(item.date), 'HH:mm, dd.MM.yyyy', {
            locale: pl,
          });
          return item;
        });

        this.loading = false;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
    async getFilteredAdminData() {
      try {
        const response = await OrderService.getAllCompanyOrders(this.mapParams(this.params));
        this.orders = response.data.map((item) => {
          item.date = format(new Date(item.date), 'HH:mm, dd.MM.yyyy', {
            locale: { ...pl, code: selectedLocale },
          });
          return item;
        });
        this.loading = false;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
    onClearDate() {
      this.date = [];
    },
    onEnter() {
      this.getTransactionsFiltered();
      if (this.$vuetify.breakpoint.smAndDown) {
        document.activeElement.blur();
      }
    },
    onSearch() {
      this.params.page = 1;
      this.getFilteredByRole();
    },
    getFilteredByRole() {
      if (this.userData.role === roles.COMPANY_ADMIN) {
        this.getFilteredAdminData();
      } else {
        this.getFilteredEmployeeData();
      }
    },
    clearParams() {
      this.params = { ...startingParams };
      this.getFilteredByRole();
    },
    fetchSort() {
      this.getFilteredByRole();
    },
  },
  created() {
    this.getFilteredByRole();
  },
};
</script>
<style lang="scss" scoped></style>
