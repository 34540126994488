import VueI18n from '../plugins/i18n';

export const visibilityOptions = () => {
  const option = [
    { value: true, text: VueI18n.t('statics.visibilityOptions.visible') },
    { value: false, text: VueI18n.t('statics.visibilityOptions.hidden') },
  ];

  return option;
};
