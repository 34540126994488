export const allAttributes = {
  MOC: { value: 'Moc', text: 'MOC' },
  AMPERAZ: { value: 'Amperaż', text: 'AMPERAZ' },
  PRZEKROJ: { value: 'Przekrój', text: 'PRZEKROJ' },
  DLUGOSC: { value: 'Długość', text: 'DLUGOSC' },
  SZEROKOSC: { value: 'Szerokość', text: 'SZEROKOSC' },
  WYSOKOSC: { value: 'Wysokość', text: 'WYSOKOSC' },
  WYMIARY: { value: 'Wymiary', text: 'WYMIARY' },
  DLUGOSC_KABLA: { value: 'Długość kabla', text: 'DLUGOSC_KABLA' },
  LICZBA_OGNIW: { value: 'Liczba ogniw', text: 'LICZBA_OGNIW' },
  WAGA: { value: 'Waga', text: 'WAGA' },
  KOLOR_RAMY: { value: 'Kolor ramy', text: 'KOLOR_RAMY' },
  CZY_FULLBLACK: { value: 'Czy fullblack', text: 'CZY_FULLBLACK' },
  GRUBOSC_MODULU: { value: 'Grubość modułu', text: 'GRUBOSC_MODULU' },
  OBSLUGIWANA_ILOSC: { value: 'supportedAmount', text: 'OBSLUGIWANA_ILOSC' },
};
