<template>
  <div>
    <v-container class="px-0 d-flex flex-row align-center" v-if="searchActive">
      <v-row>
        <v-col cols="12" :sm="filters ? '3' : '3'" class="my-auto">
          <v-text-field
            v-model="params.search"
            hide-details
            :placeholder="$t('helpers.filterName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" :sm="filters ? '2' : '4'" class="my-auto">
          <v-select
            v-model="params.producer"
            :items="producers"
            hide-details
            :placeholder="$t('helpers.producer')"
            solo
          ></v-select
        ></v-col>
        <v-col cols="12" :sm="3" v-if="filters" class="my-auto">
          <v-select
            v-model="params.category"
            :items="categoriesList"
            elevation="0"
            :label="$t('helpers.category')"
            solo
            hide-details
          ></v-select
        ></v-col>
        <v-col cols="12" :sm="filters ? '2' : '3'" class="my-auto">
          <v-select
            v-model="sortPrice"
            :items="pricingSort"
            elevation="0"
            :label="$t('helpers.filterSortByPrice')"
            solo
            hide-details
          ></v-select
        ></v-col>

        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        ></v-row
      >
    </v-container>
    <v-container class="white" :class="containerPadding ? 'pa-8' : 'pa-0'">
      <v-row v-if="onWizard || $vuetify.breakpoint.sm">
        <v-col class="font-weight-bold text-center" cols="12" sm="6">{{
          $t('helpers.product')
        }}</v-col>
        <v-col class="font-weight-bold text-center" cols="12" sm="2"
          >{{ $t('helpers.price') }} ({{ $t('helpers.net') }}) /
          {{ $t('helpers.piecesAmount') }}</v-col
        >
        <v-col class="font-weight-bold text-center" cols="12" sm="2">{{
          $t('helpers.amount')
        }}</v-col>
        <v-col class="font-weight-bold text-center" cols="12" sm="2"
          >{{ $t('helpers.sum') }} ({{ $t('helpers.net') }})</v-col
        >
      </v-row>
      <v-row v-else>
        <v-col class="font-weight-bold" cols="12" sm="6">{{ $t('helpers.product') }}</v-col>
        <v-col class="font-weight-bold text-center" cols="12" sm="2"
          >{{ $t('helpers.price') }} ({{ $t('helpers.net') }}) /
          {{ $t('helpers.piecesAmount') }}</v-col
        >
        <v-col class="font-weight-bold text-center" cols="12" sm="2">{{
          $t('helpers.amount')
        }}</v-col>
        <v-col class="font-weight-bold text-right" cols="12" sm="2"
          >{{ $t('helpers.sum') }} ({{ $t('helpers.net') }})</v-col
        >
      </v-row>
      <v-row class="mb-0">
        <v-col class="my-0 py-0"><v-divider></v-divider> </v-col>
      </v-row>
      <div v-if="!count" class="text-center mt-3">
        {{ $t('components.catalogList.productNotFound') }}
      </div>
      <catalog-list-item
        v-for="product in products"
        :key="product._id"
        :addAction="addAction"
        :removeAction="removeAction"
        :isDisabledAction="isDisabledAction"
        :onWizard="onWizard"
        :product="product"
        :isCompatibleWithPower="isCompatibleWithPower"
      ></catalog-list-item>
    </v-container>
    <v-row class="mt-3" v-if="isPaginationVisible"
      ><v-col
        ><div class="text-center">
          <v-pagination
            v-model="params.page"
            :length="length"
            :total-visible="params.limit"
          ></v-pagination></div></v-col
    ></v-row>
  </div>
</template>
<script>
import ProductService from '../services/ProductService';
import CatalogListItem from './CatalogListItem.vue';
import { pricingSort } from '../static/pricingSort';
import { categoriesComputedMixin } from '../mixins/categoriesComputedMixin';

export default {
  components: { CatalogListItem },
  mixins: [categoriesComputedMixin],
  name: 'CatalogList',
  props: {
    category: { type: String, default: '' },
    limit: { type: Number, default: 6 },
    filters: { type: Boolean, default: true },
    onWizard: { type: Boolean, default: false },
    searchActive: { type: Boolean, default: true },
    containerPadding: { type: Boolean, default: true },
    addAction: { type: String },
    removeAction: { type: String },
    isCompatibleWithPower: { type: Boolean, default: false },
    isDisabledAction: { type: Boolean, default: false },
  },
  data() {
    return {
      products: [],
      producers: [],
      sortPrice: '',
      sortPhrase: '',
      length: 1,
      count: 0,
      params: {
        page: 1,
        limit: this.limit,
        category: this.category,
        search: '',
        producer: '',
        sort: '-stock',
      },
    };
  },
  watch: {
    'params.page': function () {
      this.fetchProducts();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    sortPrice: function () {
      this.sortPhrase = this.sortPrice;
    },
  },
  methods: {
    clearParams() {
      this.sortPhrase = '';
      this.sortPrice = '';
      this.params = {
        page: 1,
        limit: this.limit,
        category: this.category,
        search: '',
        producer: '',
        sort: '-stock',
      };
      this.fetchProducts();
    },
    onSearch() {
      this.params.page = 1;
      if (this.sortPhrase) this.params.sort = this.sortPhrase;
      this.fetchProducts();
    },
    async fetchProducts() {
      try {
        const response = await ProductService.getAll({
          ...this.params,
          visibility: true,
        });
        this.producers = response.uniqueProducers;
        this.products = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    const { category } = this.$route.query;
    if (category) {
      this.params.category = category;
    }
    this.fetchProducts();
  },
  computed: {
    isPaginationVisible() {
      return this.length > 1;
    },
    pricingSort() {
      return pricingSort();
    },
  },
};
</script>
<style lang="scss" scoped></style>
