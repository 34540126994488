<template>
  <title-wrapper :title="`${$t('components.manageProducts.title')} (${count})`">
    <div>
      <v-progress-linear v-if="loading" indeterminate color="primary"> </v-progress-linear>
      <v-container v-else class="px-0">
        <v-row class="align-center">
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="params.search"
              hide-details
              :placeholder="$t('helpers.search')"
              solo
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="params.category"
              :items="categoriesList"
              :label="$t('components.manageProducts.selectLabels.chooseCategory')"
              hide-details
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="params.availability"
              :items="availabilityOptions"
              :label="$t('components.manageProducts.selectLabels.selectAvailability')"
              hide-details
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="params.visibility"
              :items="visibilityOptions"
              hide-details
              :label="$t('components.manageProducts.selectLabels.visibility')"
              solo
            ></v-select>
          </v-col>
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
              <v-icon>mdi-magnify</v-icon>
            </v-btn></v-col
          >
          <v-col cols="6" sm="1">
            <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
              <v-icon>mdi-cancel</v-icon>
            </v-btn></v-col
          >
        </v-row>
        <v-container class="white my-4">
          <v-row
            ><v-col
              ><v-data-table
                :headers="headers"
                :items="products"
                :items-per-page="params.limit"
                hide-default-footer
                :header-props="tableHeaderProps"
                class="elevation-0 nowrap"
                :item-class="itemRowText"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                @update:sort-by="sortByFunc"
                @update:sort-desc="sortDescFunc"
                :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <span>{{ getCategoryName(item.category) }}</span>
                </template>

                <template v-slot:[`item.visibility`]="{ item }">
                  <v-btn icon elevation="0" class="ml-4" @click="changeVisibility(item)">
                    <v-icon small color="black">
                      {{ item.visibility ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <span>{{ cutTooLongString(getNameByLang(item), maxStringLength) }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                      <v-icon small color="black" class="mr-2" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-card
                      ><v-list-item
                        class="hover-item-popup"
                        @click="
                          () => {
                            editProduct(item._id);
                          }
                        "
                      >
                        <v-list-item-content
                          >{{ $t('components.manageProducts.editProduct') }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-menu>
                </template></v-data-table
              ></v-col
            ></v-row
          >
          <slot name="below"></slot>
        </v-container>
        <v-row v-if="isPaginationVisible"
          ><v-col
            ><div class="text-center">
              <v-pagination
                v-model="params.page"
                :length="length"
                total-visible="5"
              ></v-pagination></div></v-col
        ></v-row>
      </v-container>
    </div>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import ProductService from '../../../../services/ProductService';
import { sortHandler } from '../../../../mixins/sortHandler';
import { getNameByLanguage } from '../../../../mixins/getNameByLanguage';
import { categoriesComputedMixin } from '../../../../mixins/categoriesComputedMixin';
import { visibilityOptions } from '../../../../static/visibilityOptions';
import { availabilityOptions } from '../../../../static/availabilityOptions';
import { tableHeaderProps } from '../../../../mixins/tableHeaderProps';

const startingParams = {
  category: '',
  search: '',
  sort: '',
  availability: '',
  visibility: '',
  page: 1,
  limit: 10,
};

export default {
  name: 'Products',
  components: { TitleWrapper },
  mixins: [sortHandler, getNameByLanguage, categoriesComputedMixin, tableHeaderProps],
  data() {
    return {
      count: 0,
      length: 0,
      products: [],
      loading: true,
      productsCount: 0,
      maxStringLength: 45,
      params: { ...startingParams },
    };
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  computed: {
    headers() {
      const headersList = [
        {
          text: this.$i18n.t('helpers.code'),
          align: 'start',
          value: 'code',
        },
        { text: this.$i18n.t('helpers.category'), value: 'category' },
        { text: this.$i18n.t('helpers.producer'), value: 'producer.name' },
        { text: this.$i18n.t('helpers.filterName'), value: 'name' },
        { text: this.$i18n.t('helpers.stock'), value: 'stock' },
        { text: this.$i18n.t('helpers.supply'), value: 'supply' },
        { text: this.$i18n.t('helpers.visibility'), value: 'visibility' },
        { text: '', value: 'actions', sortable: false },
      ];

      return headersList;
    },
    visibilityOptions() {
      return visibilityOptions();
    },
    availabilityOptions() {
      return availabilityOptions();
    },
    isPaginationVisible() {
      return this.length > 1;
    },
  },
  methods: {
    getCategoryName(category) {
      return this.categoriesList.find((item) => item.value === category)?.text;
    },
    cutTooLongString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      }
      return string;
    },
    itemRowText(item) {
      return item.visibility ? 'text-primary' : 'grey--text';
    },
    editProduct(productId) {
      this.$router.push({ name: 'SingleProduct', params: { productId: productId } });
    },
    async changeVisibility(product) {
      product.visibility = !product.visibility;
      try {
        const updatedProduct = await ProductService.update(product._id, product);
        product = updatedProduct;
      } catch (error) {
        console.error(error);
      }
    },
    clearParams() {
      this.params = { ...startingParams };
      this.getFiltered();
    },
    mapParams(params) {
      const mappedParams = { ...params };
      if (mappedParams.availability === 'Dostępne') {
        mappedParams.availability = true;
      } else if (mappedParams.availability === 'Niedostępne') {
        mappedParams.availability = false;
      }
      return mappedParams;
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        const response = await ProductService.getAll(this.mapParams(this.params));
        this.products = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },
  activated() {
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped>
.v-btn--round {
  border-radius: 4px;
}
</style>
