<template>
  <title-wrapper :title="$t('components.catalog.title')">
    <catalog-list></catalog-list>
    <v-row class="mt-3">
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <primary-button :onClick="goFoward">{{ $t('components.catalog.summary') }}</primary-button>
      </v-col>
    </v-row>
  </title-wrapper>
</template>
<script>
import CatalogList from '../../components/CatalogList.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
export default {
  components: { TitleWrapper, CatalogList, PrimaryButton },
  name: 'Catalog',
  methods: {
    goFoward() {
      this.$router.push({ name: 'TransactionSummary' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
