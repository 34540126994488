import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';
import { supportedLangs } from '../static/supportedLangs';

const url = `${config.VUE_APP_API_URL}/products`;

axios.defaults.withCredentials = true;

class ProductService {
  static async getAll(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }
  static async update(id, product) {
    const res = await axios.put(`${url}/${id}`, { ...product });
    return res.data;
  }

  static async getCategories() {
    const res = await axios.get(`${url}/categories`);
    return res.data;
  }
  static async get(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }

  static async updateImage(productId, file) {
    var formData = new FormData();
    formData.append('image', file);
    const res = await axios.put(`${url}/image/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  }

  static async deleteImage(productId) {
    const res = await axios.delete(`${url}/image/${productId}`);
    return res.data;
  }

  static async updatePdf(productId, file, lang = supportedLangs.pl.i18nCode) {
    var formData = new FormData();
    formData.append('pdfSpecification', file);
    const res = await axios.put(`${url}/spec/${productId}/${lang}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  }

  static async deletePdf(productId, lang) {
    const res = await axios.delete(`${url}/spec/${productId}/${lang}`);
    return res.data;
  }

  static async deleteFile(productId, fileId) {
    const res = await axios.delete(`${url}/${productId}/files/${fileId}`);
    return res.data;
  }

  static async addFile(productId, requestBody) {
    const formData = new FormData();
    formData.append('file', requestBody.file);
    formData.append('name', requestBody.name);
    formData.append('lang', requestBody.lang);

    const res = await axios.post(`${url}/${productId}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  }
}

export default ProductService;
