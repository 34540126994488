<template>
  <div>
    <v-col>
      <v-col v-if="isStockAvailable" class="green--text">
        <v-icon class="mr-2 mb-1" color="green">mdi-check</v-icon
        >{{ $t('components.catalogListItem.product.amountAvailable') }}:
        {{ stock }}
      </v-col>
      <v-col v-else class="error--text">
        <v-icon class="mr-2 mb-1" color="error">mdi-cancel</v-icon
        >{{ $t('components.catalogListItem.product.notAvailable') }}
      </v-col>
      <div v-if="supply" class="blue--text">
        <v-icon class="mr-2 mb-1" color="blue">mdi-calendar</v-icon
        >{{ $t('components.catalogListItem.product.delivery') }}:
        {{ supply }}
      </div>
    </v-col>
  </div>
</template>
<script>
export default {
  name: 'ProductStockInfo',
  props: {
    stock: {
      type: Number,
    },
    supply: {
      type: String,
    },
  },
  computed: {
    isStockAvailable() {
      return this.stock > 0;
    },
  },
};
</script>
