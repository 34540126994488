<template>
  <v-btn tag="a" :href="link" :class="myClass" :target="target" :icon="icon"> <slot></slot></v-btn>
</template>
<script>
export default {
  name: 'DownloadableLink',
  props: {
    link: {
      type: String,
      required: true,
    },
    myClass: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang=""></style>
