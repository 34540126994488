import VueI18n from '../plugins/i18n';

export const pricingSort = () => {
  const pricingSortArray = [
    {
      value: '-standardPrice',
      text: VueI18n.t('statics.pricingSort.descending'),
    },
    {
      value: 'standardPrice',
      text: VueI18n.t('statics.pricingSort.ascending'),
    },
  ];

  return pricingSortArray;
};
