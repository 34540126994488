<template>
  <div v-if="pdfSpecification">
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <p>
        <v-icon class="ml-3 mr-2 mb-1">$pdfIcon</v-icon
        >{{ $t('components.downloadProductSpecification.title') }}
        <v-btn tag="a" :href="pdfSpecification" download class="ml-1" target="_blank" icon
          ><v-icon>mdi-download</v-icon></v-btn
        >
      </p>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'DownloadProductSpecification',
  props: {
    pdfSpecification: {
      type: String,
    },
  },
};
</script>
